<template>
  <section>
    <div class="header pb-5">
      <app-navigation />

      <br />

      <div class="header-title">
        <div class="row">
          <div class="col-12 text-center">
            <h1>About Me</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div class="header-breadcrumb">
              <router-link to="/"><i class="fa fa-fw fa-home"></i></router-link> / About Me
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main pt-9">
      <div class="container">
        <h3 class="pb-3">Introduction</h3>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <p>Hello, my name is <strong>Adam Miller</strong> and I want to welcome you to my website. When I as 12 years old, my parents bought us our first family computer. Later that same year, we moved into our first brand new house here in Phoenix, AZ. The following year, I started middle school where I had no friends however, I quickly met another boy on my school bus that was also into computers whose dad also worked at Motorola as a programmer.</p>
            <p>Fast forward two years to our freshman year of high school. After having a discussion with the technology teacher at our high school, we were informed of new classes starting for those interested in computer networking however, these classes were only being offered to Juniors and Seniors at the time but, we were offered an in; all we had to do was take a short test that would gauge our current knowledge and if we passed, they would allow us to participate in class with the older students. After a nerve racking hour, we both successfully passed!</p>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
            <img src="https://via.placeholder.com/300" class="pt-2 pb-2" />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
            <img src="https://via.placeholder.com/300" class="pt-2 pb-2" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <p>After high school, college was not much of an option. My parents were unable to financially assist with college and I did not like the thought of debt therefore, I took a different approach. Instead of spending hours upon hours learning theory, I thought it might be a better idea to gain real experience by working in the field. While in my Junior and Senior years of high school, I had started writing HTML, CSS, and PHP to build a website for hosting LAN parties so it felt natural to find an entry-level position as a developer.</p>
            <p>After spending a couple of years as a developer, I decided to turn my attention to what I really enjoyed which was hardware. Shortly after meeting my new friend in middle school, he had started teaching me how to build computers. My first customer built computer was an Intel i486 66MHz with 32MB of memory and a 500MB hard drive. At the time, <strong>RedHat 6.1</strong> had been released so we decided to focus our attention on Linux instead of Microsoft Windows which turned out to be a very smart and lucrative decision.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <p>While in high school, I fell head over heels in love with my now wife, Samantha. We met while in marching band our freshman year and quickly became boyfriend-girlfriend. After spending a few years dating, we decided to take a break. During this time, she attended Arizona State University while I focused on growing my career. In 2005, we got back together and in 2007, we married then had our first of three daughters in 2009.</p>
            <p>Since 2007, I have focused my career on <strong>Linux Systems Administration</strong> while also designing and building out data center network environments. My biggest accomplishment was an environment that I designed, built, and managed for <strong>Iveda Solutions</strong>, a position that I accepted in 2010. The environment contained numerous servers running VMware and was designed to host a <strong>Video Surveillance as a Service (VSaaS)</strong> application written by <strong>Axis Communications</strong> for their IP surveillance cameras.</p>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
            <img src="https://via.placeholder.com/300" class="pt-2 pb-2" />
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
            <img src="https://via.placeholder.com/300" class="pt-2 pb-2" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <p>These days, I enjoy spending time with my three daughters and wife, traveling the country in our motor home as well as off roading in our Polaris RZR. During the cooler months here in Arizona, we tried to camp as much as possible whether it be in the desert, the lake or a river. We also enjoy fishing and hunting, as a family. If I am not working nor spending time with my family, I can usually be found in my garage working on our Polaris RZR, my diesel truck or one of a few other projects including golf carts and wood working. I am a dedicated family-man with a love and passion for all things <strong>Linux</strong> and <strong>DevOps</strong> however, I also enjoy my downtime.</p>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </section>
</template>

<script>
import appNavigation from '../components/Navigation'
import appFooter from '../components/Footer.vue'

export default {
  components: {
    appNavigation,
    appFooter
  },
  head: {
    title: "About Me | DevOps Engineer, AWS Engineer, Software Developer, VueJS Developer, NodeJS Developer, PHP Developer, and more | Adam L Miller",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'I am a seasoned veteran in AWS DevOps as well as Software Development using VueJS, NodeJS, PHP and more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'devops engineer, aws engineer, software developer, vuejs developer, nodejs developer, php developer'
      }
    ]
  }
}
</script>